import { concat, filter, fromPromise, map, pipe } from "wonka";
import { ObjectEnum } from "../types/enums";
import { Override } from "../types/index";
import { dateToStr, strToDate } from "../utils/dates";
import { instanceStartTimeComparitor } from "../utils/sort";
import { deserialize, upsert } from "../utils/wonka";
import { DayOfWeek, Weekdays } from "./Calendars";
import {
  DayOfWeek as DayOfWeekDto,
  Recurrence as RecurrenceDto,
  RecurringAssignmentAttendee as RecurringAssignmentAttendeeDto,
  RecurringAssignmentAttendeeStatus as RecurringAssignmentAttendeeStatusDto,
  RecurringAssignmentInstance as RecurringAssignmentInstanceDto,
  RecurringOneOnOne as RecurringOneOnOneDto,
  SubscriptionType as SubscriptionTypeDto,
  ZoomUser as ZoomUserDto,
} from "./client";
import { Event, EventStatus } from "./Events";
import { ThinPerson } from "./People";
import { Smurf } from "./Projects";
import { NotificationKeyStatus, nullable, TransformDomain } from "./types";
import { TimePolicyType, User } from "./Users";

export type { ZoomUserDto as ZoomUser };

export enum RecurringOneOnOneStatus {
  New = "NEW",
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
  Scheduled = "SCHEDULED",
}

export enum RecurringAssignmentAttendeeStatus {
  Inviting = "INVITING",
  Pending = "PENDING",
  Accepted = "ACCEPTED",
  Declined = "DECLINED",
}

export enum ConferenceType {
  None = "NONE",
  GoogleMeet = "GOOGLE_MEET",
  Zoom = "ZOOM",
  Custom = "CUSTOM",
}

export enum RedirectActions {
  ResumeEdit = "ResumeEdit",
}

export class Recurrence extends ObjectEnum {
  static Daily = new Recurrence(RecurrenceDto.Daily, "Daily");
  static Weekly = new Recurrence(RecurrenceDto.Weekly, "Weekly");
  static Biweekly = new Recurrence(RecurrenceDto.Biweekly, "Every other week");
  static Monthly = new Recurrence(RecurrenceDto.Monthly, "Monthly");
  static Quarterly = new Recurrence(RecurrenceDto.Quarterly, "Quarterly");

  constructor(public readonly key: string, public readonly label: string) {
    super(key);
  }

  static get Options(): Recurrence[] {
    return Object.values(Recurrence);
  }

  public get dto(): RecurrenceDto {
    return RecurrenceDto[this.key];
  }
}

// FIXME (IW): client doesn't match payload
export type RecurringAssignmentAttendee = Override<
  RecurringAssignmentAttendeeDto,
  {
    readonly self: boolean;
    responseStatus: RecurringAssignmentAttendeeStatus;
    // inviteSentAt?: Date;
  }
>;

export type RecurringAssignmentInstance = Override<
  RecurringAssignmentInstanceDto,
  {
    eventKey?: string;
    eventStatus?: EventStatus;
    attendees?: RecurringAssignmentAttendee[];

    start?: Date;
    end?: Date;
  }
>;

export type OneOnOne = Override<
  RecurringOneOnOneDto,
  {
    readonly id: number;
    invitee?: ThinPerson;

    windowStart?: string; // comes through in client typed as LocalTime which is just a string
    windowEnd?: string; // comes through in client typed as LocalTime which is just a string
    idealTime?: string; // comes through in client typed as LocalTime which is just a string
    idealDay?: DayOfWeek;
    daysActive?: DayOfWeek[];
    timePolicy?: string | null;
    recurrence: Recurrence;
    organizer?: ThinPerson;

    priority?: Smurf;
    snoozeUntil?: Date | null;

    status: RecurringOneOnOneStatus;
    conferenceType: ConferenceType;
    organizersTimeZone: string;

    readonly instances?: RecurringAssignmentInstance[];
    readonly updated?: Date;
  }
>;

export function dtoToRecurringAssignmentInstance(dto: RecurringAssignmentInstanceDto): RecurringAssignmentInstance {
  return {
    ...dto,
    eventKey: dto.eventKey as unknown as string,
    eventStatus: dto.eventStatus as unknown as EventStatus,
    attendees: dto.attendees as unknown as RecurringAssignmentAttendee[],
    start: strToDate(dto.start),
    end: strToDate(dto.end),
  };
}

export function dtoToOneOnOne(dto: RecurringOneOnOneDto): OneOnOne {
  return {
    ...dto,
    id: (!!dto.id ? dto.id : undefined) as unknown as number, // strip 0 ids (long id == 0),
    idealDay: dto.idealDay as unknown as DayOfWeek,
    daysActive: dto.daysActive as unknown as DayOfWeek[],
    snoozeUntil: nullable(dto.snoozeUntil, strToDate),
    status: dto.status as unknown as RecurringOneOnOneStatus,
    instances: dto.instances?.map(dtoToRecurringAssignmentInstance),
    updated: strToDate(dto.updated),
    recurrence: Recurrence.get(dto.recurrence) || Recurrence.Weekly,
    conferenceType: (dto.conferenceType as unknown as ConferenceType) || ConferenceType.None,
    organizersTimeZone: dto.organizersTimeZone!,
  };
}

export function OneOnOneToDto(oneOnOne: Partial<OneOnOne>): Partial<RecurringOneOnOneDto> {
  const dto = {
    ...oneOnOne,
    idealDay: oneOnOne.idealDay as unknown as DayOfWeekDto,
    daysActive: oneOnOne.daysActive as unknown as DayOfWeekDto[],
    snoozeUntil: nullable(oneOnOne.snoozeUntil, dateToStr),
    timePolicy: oneOnOne.timePolicy,
    recurrence: oneOnOne.recurrence?.toJSON() as RecurrenceDto,
    invitee: oneOnOne.invitee as unknown as RecurringOneOnOneDto["invitee"],
    status: oneOnOne.status as unknown as RecurringOneOnOneDto["status"],
    instances: oneOnOne.instances as unknown as RecurringAssignmentInstanceDto[],
    updated: dateToStr(oneOnOne.updated),
    conferenceType: (oneOnOne.conferenceType === ConferenceType.None
      ? null
      : oneOnOne.conferenceType) as unknown as RecurringOneOnOneDto["conferenceType"],
    organizersTimeZone: oneOnOne.organizersTimeZone,
  };

  // TODO: (SS) This can be removed when we only send changed values in the patch.
  if ((dto.conferenceType as unknown as ConferenceType) === ConferenceType.Custom) {
    delete dto.conferenceType;
  }

  return dto;
}

const OneOnOneSubscription = {
  subscriptionType: SubscriptionTypeDto.OneOnOne,
};

export class OneOnOnesDomain extends TransformDomain<OneOnOne, RecurringOneOnOneDto> {
  resource = "OneOnOne";
  cacheKey = "oneOnOne";
  pk = "id";

  public serialize = OneOnOneToDto;
  public deserialize = dtoToOneOnOne;

  watchAll$ = pipe(
    this.ws.subscription$$(OneOnOneSubscription),
    filter((envelope) => !!envelope.data),
    map((envelope) => envelope.data),
    deserialize(this.deserialize),
    map((items) => this.patchExpectedChanges(items))
  );

  watch$$ = (instances?: boolean) => {
    const subscription = {
      subscriptionType: SubscriptionTypeDto.OneOnOne,
      instances: !!instances, // FIXME (IW): Need to add this on the backend
    };

    return pipe(
      this.ws.subscription$$(subscription),
      filter((envelope) => !!envelope.data),
      map((envelope) => envelope.data),
      deserialize(this.deserialize),
      map((items) => this.patchExpectedChanges(items))
    );
  };

  list$$ = (instances?: boolean) =>
    pipe(
      fromPromise(Promise.all([this.list(instances), this.invites(instances)])),
      map(([list, invites]) => this.patchExpectedChanges([...list, ...invites]))
    );

  listAndWatch$$ = (instances?: boolean) => {
    return pipe(
      concat<OneOnOne[] | OneOnOne>([this.list$$(instances), this.watch$$(instances)]),
      upsert((e) => this.getPk(e)),
      map((items) => [...items])
    );
  };

  watchId$$ = (id: number) => {
    return pipe(
      this.watchAll$,
      map((items) => items?.find((i) => i.id === id))
    );
  };

  list = this.manageErrors(
    this.deserializeResponse((instances?: boolean) => this.api.oneOnOne.getOneOnOnes({ instances }))
  );

  get = this.deserializeResponse((id: number, instances?: boolean) => this.api.oneOnOne.getOneOnOne(id, { instances }));

  create = this.manageErrors(
    this.deserializeResponse((oneOnOne: Omit<OneOnOne, "id">) =>
      this.api.oneOnOne.createOnOnOne(this.serialize(oneOnOne) as RecurringOneOnOneDto)
    )
  );

  patch = this.manageErrors(
    this.deserializeResponse(async (id: number, patch: Partial<OneOnOne>) => {
      const notificationKey = this.generateUid("patch", id);

      this.expectChange(notificationKey, id, patch, true);

      return this.api.oneOnOne
        .patchOneOnOne(id, this.serialize(patch), { notificationKey })
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  delete = this.manageErrors((id: number) => {
    const notificationKey = this.generateUid("delete", id);

    this.expectChange(notificationKey, id, { deleted: true });

    return this.api.oneOnOne
      .deleteOneOnOne(id, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  });

  invites = this.manageErrors(
    this.deserializeResponse((instances?: boolean) => this.api.oneOnOne.getMeetingInvites({ instances }))
  );

  inviteWithErrors = this.deserializeResponse((id: number, instances?: boolean, inviteKey?: string) =>
    this.api.oneOnOne.getMeetingInvite(id, { instances, inviteKey })
  );

  invite = this.manageErrors(this.inviteWithErrors);

  reschedule = this.manageErrors((eventId: string) => this.api.oneOnOne.reschedule(eventId, {}));

  cancel = this.manageErrors((eventId: string, oneOnOneId: number) =>
    this.api.oneOnOne.cancel(eventId, oneOnOneId, {})
  );

  suggestions = this.manageErrors(this.deserializeResponse(this.api.oneOnOne.getSuggestions));

  respond = this.manageErrors((id: number, accept: boolean) => {
    const status = accept ? RecurringAssignmentAttendeeStatus.Accepted : RecurringAssignmentAttendeeStatus.Declined;
    const notificationKey = this.generateUid("respond", id);

    this.expectChange(notificationKey, id, {
      status: accept ? RecurringOneOnOneStatus.Accepted : RecurringOneOnOneStatus.Declined,
    });

    return this.api.oneOnOne
      .respond(id, { status: status as unknown as RecurringAssignmentAttendeeStatusDto }, { notificationKey })
      .then((res) => {
        this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
        return res;
      })
      .catch((reason) => {
        console.warn("Request failed, clearing notification key", notificationKey, reason);
        this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
        throw reason;
      });
  });
}

export const defaultOneOnOne: Pick<
  OneOnOne,
  | "title"
  | "additionalDescription"
  | "timePolicy"
  | "recurrence"
  | "daysActive"
  | "idealTime"
  | "windowStart"
  | "windowEnd"
  | "duration"
  | "conferenceType"
> = {
  title: "",
  additionalDescription: "",
  timePolicy: TimePolicyType.Meeting,
  recurrence: Recurrence.Weekly,
  daysActive: Weekdays,
  idealTime: "2:00 pm",
  windowStart: "8:00 am",
  windowEnd: "6:00 pm",
  duration: 30,
  conferenceType: ConferenceType.None,
};

export function makeDefaultOneOnOne(user?: User | null): OneOnOne {
  return {
    ...defaultOneOnOne,
    status: RecurringOneOnOneStatus.New,
    updated: new Date(),
    organizersTimeZone: user?.timezone.displayName || "Eastern Time",
  } as OneOnOne;
}

export const getNextMeetingFromEvent = (data: OneOnOne, event: Event): RecurringAssignmentInstance | null => {
  if (!data.instances?.length || !event) return null;

  const ins = data.instances.sort(instanceStartTimeComparitor);
  const currentIdx = ins.findIndex((i) => i.eventId === event.eventId);

  return currentIdx >= 0 && !!ins[currentIdx + 1] ? ins[currentIdx + 1] : null;
};

export const getNextUpcomingMeeting = (data: OneOnOne): RecurringAssignmentInstance | null => {
  let next: RecurringAssignmentInstance | null = null;
  const now = new Date();

  if (!!data.instances?.length) {
    data.instances.forEach((i) => {
      if (!next || (!!i.end && i.end < now && !!next && !!i.start && !!next.start && i.start < next.start)) {
        next = i;
      }
    });
  }

  return next;
};

export const isOrganizer = (data: OneOnOne, user: User): boolean =>
  !!data.organizerUserId && !!user && user.id === data.organizerUserId;
